<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-06-07 10:23:20
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-06-11 16:02:03
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\SManagerEduMoralStatisticsScrollWrapper\sub\layout-secound.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="layout-secound">
        <div class="top">
            <div class="top-center">
                <div class="title">
                    <div class="icon"></div>
                    {{ title }}
                </div>
            </div>
            <div class="top-left">
                <slot name="topLeft"></slot>
            </div>
            
            <div class="top-right">
                <slot name="topRight"></slot>
            </div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name:'layoutSecound',
    props: {
        title: {
            type: String,
            default: ""
        },
    },
}
</script>

<style lang="scss" scoped>
    .layout-secound{
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #FFFFFF;
        box-shadow: 0px 6px 6px 0px rgba(179,179,179,0.24);
        border-radius: 10px;
        .top {
            width: 100%;
            height: 33px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .top-center {
                position: relative;
                .text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    width: 100%;
                    height: 26px;
                    z-index: 1px;
                    font-weight: bold;
                    font-size: 18px;
                    color: #111111;
                }
            }

            .top-left {
                box-sizing: border-box;
                flex: 1;
                margin-top: 5px;
            }

            .top-right {
                box-sizing: border-box;
                margin-right: 25px;
            }

        }

        .content {
            width: 100%;
        }
    }
    .title {
            //
            position: relative;
            width: 200px;
            // border: 0px solid #3c7fff;
            // // border-right:none;
            // border-bottom: 1px solid #fff;

            font-weight: bold;
            font-size: 18px;
            color: #111111;
            box-sizing: border-box;
            padding-top: 9px;
            display: flex;
            align-items: center;

            .icon {
                width: 10px;
                height: 20px;
                background: #3c7fff;
                border-radius: 0px 10px 10px 0px;
                margin-right: 12px;
            }
        }
</style>