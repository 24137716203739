<!--
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2024-06-07 14:49:20
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2024-06-17 14:20:52
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\SManagerEduMoralStatisticsScrollWrapper.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
    <div class="attendance-statistics">
        <expand-filter
            :formData="formData"
            @clickBtn="filterClick"
            @dataChange="dataChange"
            :closeWidth="700"
            margin-bottom="0"
        >
        </expand-filter>

        <div 
            class="content"
            :style="{'height': contentHeight + 'px'}"
        >   
            <div class="left">
                <div class="assessment">
                    <assessment-trends :data="examineTrendData"></assessment-trends>
                </div>

                <div class="classroom">
                    <classroom-quantification 
                        :data="classData"
                        :classCurrentGrade.sync="classCurrentGrade"
                        @handleGradeChange="handleGradeChange"
                    ></classroom-quantification>
                </div>
                


            </div>
            <div class="center">
                <layout-secound
                    :title="'数据概览'"
                >
                    <div class="center-content">
                        <data-overview :data="schooldData"></data-overview>
                    </div>

                    <overview-of-grade-assessment 
                        ref="overviewOfGradeAssessment" 
                        :data="gradeExamineInfo"
                        :listQuery="listQuery"
                    ></overview-of-grade-assessment>
                </layout-secound>
            </div>
            <div class="right">
                <div class="grade">
                    <grade-quantification :data="gradeData"></grade-quantification>
                </div>

                <div class="sup">
                    <supervision :data="moralUnHandleData"></supervision>
                </div>

                
            </div>

        </div>
    </div>
</template>

<script>
import * as echarts from "echarts"
import ExpandFilter from "./Sub/ExpandFilter.vue";
import {downloadFile, listToTree, hasPermission} from "@/libs/utils.js";
import layoutFirst from "@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/sub/layout-first.vue"
import layoutSecound from '@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/sub/layout-secound.vue';
import AssessmentTrends from '@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/AssessmentTrends.vue';
import ClassroomQuantification from '@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/ClassroomQuantification.vue';
import DataOverview from '@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/DataOverview.vue';
import OverviewOfGradeAssessment from '@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/OverviewOfGradeAssessment.vue';
import GradeQuantification from '@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/GradeQuantification.vue';
import Supervision from '@/components/scrollWrapper/SManagerEduMoralStatisticsScrollWrapper/Supervision.vue';
export default {
    name: "SManagerEduMoralStatisticsScrollWrapper",
    components: {
        ExpandFilter,
        layoutFirst,
        layoutSecound,
        AssessmentTrends,
        ClassroomQuantification,
        DataOverview,
        OverviewOfGradeAssessment,
        GradeQuantification,
        Supervision
    },
    data() {
        return {
            listQuery: {
                schoolId:this.$store.state.schoolId,
                startTime:'',
                endTime:''

            },
            
            formData: {
                data: [
                    {
                        type: "datePick",
                        width: "350px",
                        key: "dateArr",
                        value: [],
                        dateType: "daterange",
                        format: "yyyy-MM-dd",
                        valueFormat: "yyyy-MM-dd",
                        clearable: false,
                        pickerOptions: {
                            disabledDate: (time) => {
                                return time.getTime() > Date.now();
                            },
                        },
                    },
                ],
                btnList: [
                    // {
                    //     type: "primary",
                    //     text: "查询",
                    //     fn: "primary",
                    //     auth: ["checkattendance:monthanalysis:list"],
                    // },
                    // {
                    //     type: "enquiry",
                    //     text: "重置",
                    //     fn: "reset",
                    // },
                ],
                btnFormType: true,
            },

            options:[],
            gradeExamineInfo:[],//年级考核
            classData:[],//班级量化
            classCurrentGrade:"",//班级量化-当前年级
            gradeData:[],//年级量化
            examineTrendData:[],//考核趋势
            schooldData:{},//数据概览
            moralUnHandleData:[],//德育监督

        };
    },
    computed: {
        contentHeight() {
            return document.body.clientHeight - 176
        },
        demoUrl() {
            return require("@/assets/images/eduMoralStatistics/demo.png");
        }
    },
    created () {
        this.setDefaultTime();;
    },
    mounted() {
        
        // this.getGradeExamineInfo();
        this.getClassData();
        this.getGradeData();
        this.getExamineTrend();
        this.getSchoolData();
        this.getMoralUnHandle();
        // this.getOrgList();
        // this.getCheckList();
    },
    methods: {
        filterClick(item) {
            if (item.item.fn === "reset") {
                this.formData.data.forEach((item) => {
                    if(item.key == 'dateArr'){
                        item.value = []
                    } else {
                        item.value = ""
                    }
                })
                // this.formData.data = this.$options.data().formData.data;
                this.setDefaultTime();
            }
            this.getData();
        },
        changeBtnFormType() {},
        
        
        setDefaultTime() {
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    let startDate = this.$moment().startOf('months').format("YYYY-MM-DD");
                    let currentDate = this.$moment().format("YYYY-MM-DD");
                    item.value = [startDate, currentDate];
                    this.listQuery.startTime = startDate;
                    this.listQuery.endTime = currentDate;
                }
            });
        },

        dataChange(data) {
            console.log(data);
            data.forEach((item) => {
                if (item.key === "dateArr") {
                    let [startDate, currentDate] = item.value ;
                    this.listQuery.startTime = startDate;
                    this.listQuery.endTime = currentDate;
                }
            });

            // this.getGradeExamineInfo();
            this.getClassData();
            this.getGradeData();
            // this.getExamineTrend();
            this.getSchoolData();
            this.getMoralUnHandle();
        },

        // 年级考核
        getGradeExamineInfo() {
            this._fet(
                "/school/schoolMoralScreenData/gradeExamineInfo",
                this.listQuery,
            ).then( res => {
                if(res.data.code == "200"){
                    this.gradeExamineInfo = res.data.data;
                    this.$nextTick(() => {
                        this.$refs.gradeExamineInfo.initChart();
                    })
                }
            })
        },
        // 班级量化
        getClassData() {
            let param = {
                ...this.listQuery,
                orderType:'1',
                gradeId:this.classCurrentGrade
            };
            this._fet(
                "/school/schoolMoralScreenData/classData",
                param,
            ).then( res => {
                if(res.data.code == "200"){
                    this.classData = res.data.data;
                }
            })
        },
        // 年级量化
        getGradeData() {
            this._fet(
                "/school/schoolMoralScreenData/gradeData",
                this.listQuery,
            ).then( res => {
                if(res.data.code == "200"){
                    this.gradeData = res.data.data;
                }
            })
        },
        // 考核趋势
        getExamineTrend() {
            let param = {
                schoolId:this.$store.state.schoolId
            }
            this._fet(
                "/school/schoolMoralScreenData/examineTrend",
                param,
            ).then( res => {
                if(res.data.code == "200"){
                    this.examineTrendData = res.data.data;
                }
            })
        },
        // 数据概览
        getSchoolData() {
            this._fet(
                "/school/schoolMoralScreenData/schoolData",
                this.listQuery,
            ).then( res => {
                if(res.data.code == "200"){
                    this.schooldData = res.data.data;
                }
            })
        },
        // 德育督导
        getMoralUnHandle() {
            this._fet(
                "/school/schoolMoralScreenData/moralUnHandle",
                this.listQuery,
            ).then( res => {
                if(res.data.code == "200"){
                    this.moralUnHandleData = res.data.data;
                }
            })
        },
        // 班级量化-年级改变
        handleGradeChange(val) {
            console.log(val);
            this.getClassData();
        }

    },
};
</script>

<style lang="scss" scoped>
.attendance-statistics {
    overflow: auto;
    .content {
        display: flex;
        // overflow: auto;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        padding-top: 10px;
        // margin-top: 8px;
        // justify-content: space-between;

        .left {
            // width: 530px;
            flex: 1;
            .assessment {
                width: 100%;
            }

            .classroom {
                width: 100%;
                margin-top: 10px;
            }
            
        }

        .center {
            // width: 622px;
            max-width: 622px;
            height: 790px;
            margin-left: 10px;

            .center-content {
                margin-bottom: 31px;
            }
        }

        @media screen and (max-width: 1540px) {
            .center {
                max-width: 500px;
            }
        }

        .right {
            // width: 530px;
            flex: 1;
            margin-left: 10px;
            margin-right: 10px;

            .sup {
                width: 100%;
                margin-top: 10px;
            }
        }
           
    }
}
</style>
