<template>
    <div class="layout-first">
        <div class="top">
            <div class="title">
                <div class="icon"></div>
                {{ title }}
            </div>
           
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "默认标题",
        },
        // squareNum: {
        //     type: Number,
        //     default: 8
        // },
    },
};
</script>

<style lang="scss" scoped>
.layout-first {
    width: 100%;
    height: 100%;

    .top {
        width: 100%;
        height: 35px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        // overflow: hidden;
        .title {
            //
            position: relative;
            width: 200px;
            height: 107%;
            // border: 0px solid #3c7fff;
            // // border-right:none;
            // border-bottom: 1px solid #fff;

            background: #fff;
            font-weight: bold;
            font-size: 18px;
            color: #111111;
            box-sizing: border-box;
            padding-top: 9px;
            display: flex;
            align-items: center;


            .icon {
                width: 10px;
                height: 20px;
                background: #3c7fff;
                border-radius: 0px 10px 10px 0px;
                margin-right: 12px;
            }
        }

        .img {
            width: calc(100% - 200px);
            height: 100%;
            display: flex;
            margin-left: 10px;
            .square1 {
                width: 30%;
                height: 28px;
                background: linear-gradient(0deg, #3c7fff 0%, #9dfeff 100%);
                text-decoration: none;
                transform: skewX(30deg);
            }
            .square {
                width: 6%;
                height: 28px;
                background: linear-gradient(0deg, #3c7fff 0%, #9dfeff 100%);
                text-decoration: none;
                transform: skewX(30deg);
                margin-left: 3%;
            }
        }
    }

    .content {
        width: 100%;
        height: calc(100% - 25px);
        border-top-left-radius: 0px;
        background-color: #fff;
    }
}
</style>